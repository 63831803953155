export const PricingModel = [
	{
		title: 'Pay as you Go',
		description:
			'Charges apply per individual use. Ideal for customers with variable or unpredictable usage volumes.',
	},
	{
		title: 'Tier-Based Pricing',
		description:
			'Prices decrease as usage volumes increase. Designed for high-volume users with predictable needs.',
	},
	{
		title: 'Flat',
		description:
			'A fixed price without limitations or variables that might alter the cost.',
	},
	{
		title: 'Multiplier',
		description:
			'Charges are applied in increments based on usage quotas. Even a small excess over the quota results in billing for the next increment.',
	},
];
export const Glossary = [
	{
		title: 'Product/Service',
		description: 'The specific product or service SQE provides to clients.',
	},
	{
		title: 'Model',
		description: 'The pricing approach - either Pay As You Go or Tier-Based.',
	},
	{
		title: 'Volume',
		description: 'The measurable unit of service usage (numbers).',
	},
	{
		title: 'Price',
		description: 'The cost per unit for the respective product/service.',
	},
	{
		title: 'Charge',
		description: 'The billing unit (e.g., per-hit, per-stamp).',
	},
	{
		title: 'Free of Charge',
		description: 'Indicates no fees are applied for the service.',
	},
	{
		title: 'Tax',
		description: 'Applicable taxes based on the type of service used.',
	},
	{
		title: 'Taxable',
		description: 'The service is subject to taxation.',
	},
	{
		title: 'Per-signature',
		description:
			'Billing calculated based on the number of signatures processed.',
	},
	{
		title: 'Per-agent',
		description:
			'Charges determined by the number of active users (agents) utilizing the service.',
	},
	{
		title: 'Per-minute',
		description:
			'Billing calculated according to the duration of service usage, measured in minutes.',
	},
];
export const FAQ = [
	{
		title: 'How does Pay As You Go pricing work?',
		description:
			'For services under Pay As You Go, you are billed only for the units you use. There is no minimum usage or subscription required.',
	},
	{
		title: 'What is Tier-Based Pricing, and how is it calculated?',
		description:
			'Tier-Based Pricing provides a discount as the volume of usage increases. Each tier offers a reduced rate, encouraging high-volume usage with better cost efficiency.',
	},
	{
		title: 'Are taxes included in the listed prices?',
		description:
			'Taxes are not included in the listed prices. Taxable services will include applicable taxes in the final billing.',
	},
	{
		title: 'How do I calculate charges for my usage?',
		description:
			'Multiply your usage volume by the price per unit (as shown in the table) for each product/service.',
	},
	{
		title: 'What data will be sent along with the invoice?',
		description:
			'We will provide a draft billing invoice accompanied by usage evidence related to the utilized services.',
	},
	{
		title:
			'Will the data be encrypted? If so, what data points can users crosscheck?',
		description:
			'The usage evidence provided is encrypted and does not contain personal data. Crosschecking depends on the service used and can be reconciled with the client’s internal data.',
	},
	{
		title: 'What is the billing cut-off date each month?',
		description:
			'Billing cut-off is conducted between the 1st and 4th of every month or as specified in the service contract.',
	},
	{
		title: 'Are analytics dashboard fees applied automatically?',
		description:
			'We offer an optional analytics dashboard to monitor general service usage. Access to the SQE-Portal costs a flat fee of IDR 1,000,000 per month. For SQE-CC, a dedicated dashboard is automatically charged at the same rate upon service use.',
	},
	{
		title: 'How can I gain access to the analytics dashboard?',
		description:
			'To request access, please contact your designated PIC (Product Manager/Technical Project Manager) via WhatsApp or email.',
	},
	{
		title: 'Are there any setup fees?',
		description:
			'No, all SQE services are billed based on usage. Integration and implementation are free of charge.',
	},
	{
		title:
			'For KYC, OCR, and liveness hits, are charges applied to total hits or successful hits only?',
		description:
			'For SQE-KYC services, charges apply to all API hits, including both successful and failed ones.',
	},
	{
		title: 'Is there a feature to remind agents to end voice or video calls?',
		description:
			'Currently, this feature is not available for SQE-CC voice/video call services.',
	},
	{
		title: 'For MP messages, are only successful messages charged?',
		description:
			'All sent messages, regardless of success or failure, are charged under the SQE-MP service.',
	},
	{
		title: 'What does "active user" and "first login" mean in SQE-ID?',
		description: (
			<div>
				<ul className="list-disc">
					<li className="ml-6">
						<span className="font-bold">Active User:</span> A user with an
						active session who performs authentication or refresh token actions
						within a month. After 30+1 days of inactivity, the user is no longer
						considered active.
					</li>
					<li className="ml-6">
						<span className="font-bold">First Login:</span> A charge is applied
						the first time a unique user logs in (or registers) using SQE-ID
						SSO. Subsequent logins are free of charge.
					</li>
				</ul>
			</div>
		),
	},
	{
		title: 'Will testing or trial usage incur charges?',
		description:
			'No charges are applied for testing or Proof of Concept (PoC) phases. However, trial usage is subject to limits or quotas. For more details, contact your service PIC.',
	},
];
