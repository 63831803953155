import { Paragraph, Title, Divider, Icon, Tabs } from '@squantumengine/horizon';
import { Collapse } from 'antd';
import SpTable from 'common/components/sp-table';
import style from './index.module.scss';
import { LIST } from './pricing-guideline-table.constants';
import { FAQ, Glossary, PricingModel } from './pricing-guideline.constants';

const PriceGuideline = () => {
	return (
		<div className="py-8">
			<div className="flex flex-col gap-6">
				<Title level={4}>SQE Pricing Guide FAQ</Title>
				<Paragraph className="text-secondary">
					This document provides a comprehensive guide on the pricing models,
					definitions, and details of each product/service offered by SQE. It is
					accessible to all partners and customers to ensure transparency and
					ease of understanding.
				</Paragraph>
			</div>
			<Divider type="solid" className="my-14 border border-[#F2F3F5] " />
			<div className="flex flex-col gap-6">
				<Title level={4}>Pricing Model Overview</Title>
				<div className="flex flex-col gap-3">
					<Paragraph className="text-secondary">
						We offer four pricing models to meet various customer needs:
					</Paragraph>
					<div className="grid grid-cols-4 gap-3">
						{PricingModel.map((model) => (
							<div
								className="flex flex-col gap-3 bg-neutral-0 shadow-light-lg rounded-lg p-4"
								key={model.title}
							>
								<Title level={6}>{model.title}</Title>
								<Paragraph className="text-secondary">
									{model.description}
								</Paragraph>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="flex flex-col gap-3 mt-14">
				<Title level={4} className="mb-6">
					Glossary
				</Title>
				<div className="flex flex-col gap-4">
					<div className="grid grid-cols-4 gap-3">
						{Glossary.map((model) => (
							<div
								className="flex flex-col gap-3 bg-neutral-0 shadow-light-lg rounded-lg p-4"
								key={model.title}
							>
								<Title level={6}>{model.title}</Title>
								<Paragraph className="text-secondary">
									{model.description}
								</Paragraph>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="flex flex-col gap-3 mt-14">
				<Title level={4} className="mb-6">
					Pricing Table and Details
				</Title>
				<div className="flex flex-col gap-10">
					<Collapse
						className={style.collapse}
						accordion
						expandIconPosition="end"
						ghost
						expandIcon={({ isActive }) => (
							<Icon
								name={isActive ? 'chevron-small-up' : 'chevron-small-down'}
							/>
						)}
						items={LIST.map((item, index) => {
							return {
								label: (
									<div className="flex items-center gap-5">
										<img
											src={item.image}
											alt={item.name}
											className="h-full w-auto"
										/>
										<div className="flex flex-col gap-1">
											<Title level={6}>{item.name}</Title>
											<Paragraph className="text-secondary">
												{item.description}
											</Paragraph>
										</div>
									</div>
								),
								children: (
									<div className="flex flex-col gap-10 mt-10">
										<SpTable
											className={style.table}
											pagination={false}
											defaultTable
											bordered
											columns={item.data.tableColumns}
											dataSource={item.data.tableData}
										/>
										<div>
											<Title level={6}>Use Case</Title>
											{item.name === 'SQEPortal' ? (
												item.data.tabsData[0].children
											) : (
												<Tabs
													defaultActiveKey={item.data.tabsData[0].key}
													items={item.data.tabsData}
													tabBarGutter={24}
												/>
											)}
										</div>
									</div>
								),
								className: `bg-neutral-0 shadow-light-lg rounded-lg p-6 ${
									LIST.length - 1 && 'mb-4'
								}`,
							};
						})}
					/>
				</div>
			</div>
			<div className="flex flex-col gap-3 mt-14">
				<Title level={4} className="mb-6">
					FAQ
				</Title>
				<Collapse
					accordion
					expandIconPosition="end"
					ghost
					expandIcon={({ isActive }) => (
						<Icon name={isActive ? 'chevron-small-up' : 'chevron-small-down'} />
					)}
					items={FAQ.map((faq) => ({
						label: <Title level={6}>{faq.title}</Title>,
						children: <div className="text-secondary">{faq.description}</div>,
						className: 'bg-neutral-0 shadow-light-lg rounded-lg p-2 mb-4',
					}))}
				/>
			</div>{' '}
			<div className="flex flex-col gap-3 mt-14">
				<Title level={4} className="mb-3">
					Contact Us
				</Title>
				<Paragraph className="text-secondary">
					If you encounter any issues or have questions regarding our services,
					we’re here to help. You can reach out to us directly through the
					following channels:
				</Paragraph>
				<div className="grid grid-cols-2 mt-6 gap-3">
					<div className="bg-neutral-0 shadow-light-lg rounded-lg p-4">
						<div className="flex gap-5">
							<Icon name="email" size="xl" />
							<div className="flex flex-col gap-3">
								<Title level={6}>Email</Title>
								<div className="">
									<Paragraph weight="bold">
										Febryan Permana (Product Manager)
									</Paragraph>
									<Paragraph>febryan.permana@sqe.co.id</Paragraph>
								</div>
								<div className="">
									<Paragraph weight="bold">
										Imam Chairudin (Technical Project Manager)
									</Paragraph>
									<Paragraph>imam.chairudin@smma.id</Paragraph>
								</div>
							</div>
						</div>
					</div>
					<div className="bg-neutral-0 shadow-light-lg rounded-lg p-4">
						<div className="flex gap-5">
							<Icon name="whatsapp" size="xl" />
							<div className="flex flex-col gap-3">
								<Title level={6}>Whatsapp</Title>
								<Paragraph className="text-secondary">
									For real-time support and collaborative discussions, please
									utilize the WhatsApp group created specifically for your
									project.
								</Paragraph>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PriceGuideline;
