import { Suspense, lazy } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ManagerSkeleton from 'features/main-dashboard/manager/dashboard/skeleton';
import PengelolaSkeleton from 'features/main-dashboard/pengelola/skeleton';
import { useProfileStore } from 'common/stores/profile';
import ToastMessage from 'common/utils/toast-message';
import { camelize } from 'common/utils/transformer';
import type { ProfileOrganisations } from 'common/types/auth';
import { ROLES, GENERAL_ERROR_MESSAGE } from 'common/constants/index';
import { getInfo } from 'services/auth';

const PengelolaDashboard = lazy(
	() =>
		import(
			/* webpackChunkName: "pengelolaDashboard" */ 'features/main-dashboard/pengelola-dashboard'
		)
);
const ManagerDashboard = lazy(
	() =>
		import(
			/* webpackChunkName: "managerDashboard" */ 'features/main-dashboard/manager-dashboard'
		)
);

const MainDashboard = () => {
	const navigate = useNavigate();

	const role = useProfileStore((state) => state.role);
	const setOrganisations = useProfileStore((state) => state.setOrganisations);
	const checkPermission = useProfileStore((state) => state.checkPermission);

	const { isFetching } = useQuery({
		queryKey: ['getProfileDashboard'],
		queryFn: getInfo,
		onSuccess: (response) => {
			const organisations = response?.data?.user?.organisations ?? [];
			const sanitizedResponse = camelize(
				organisations
			) as ProfileOrganisations[];

			setOrganisations(sanitizedResponse);

			if (
				!checkPermission('manager', 'homepage') &&
				!checkPermission('organisation', 'homepage')
			) {
				return navigate('/unauthorized');
			}
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const skeletonComponent =
		role === 'pengelola' ? <PengelolaSkeleton /> : <ManagerSkeleton />;

	const pengelola =
		role === ROLES.PENGELOLA.toLowerCase() ||
		role === ROLES.GMRA_EDITOR.toLowerCase() ||
		role === ROLES.GMRA_VIEWER.toLowerCase();

	const manager =
		role === ROLES.MANAGER.toLowerCase() ||
		role === ROLES.INTERNAL_USER.toLowerCase();
	return (
		<div className="py-8">
			{isFetching ? (
				skeletonComponent
			) : (
				<Suspense fallback={skeletonComponent}>
					{manager && <ManagerDashboard />}
					{pengelola && <PengelolaDashboard />}
				</Suspense>
			)}
		</div>
	);
};

export default MainDashboard;
