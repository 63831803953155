import { Paragraph } from '@squantumengine/horizon';

export const SQEKYC = {
	tableColumns: [
		{
			title: 'Produk / Service',
			dataIndex: 'productService',
		},
		{ title: 'Model', dataIndex: 'model' },
		{ title: 'Volume', dataIndex: 'volume' },
		{ title: 'Price', dataIndex: 'price' },
		{ title: 'Charge', dataIndex: 'charge' },
		{ title: 'Tax', dataIndex: 'tax' },
	],
	tableData: [
		{
			productService: 'Canvas Signing',
			model: 'As you go',
			volume: '1',
			price: 'Free',
			charge: 'Gratis',
			tax: 'Free',
		},
		{
			productService: 'Digital Signature',
			model: 'As you go',
			volume: '1',
			price: 'Rp660',
			charge: 'Per tanda tangan',
			tax: 'Taxeable',
		},
		{
			productService: 'E-KYC (Electronic Know Your Customer)',
			model: 'Tier',
			volume: '1 - 1.000.000',
			price: 'Rp2.380',
			charge: 'Per hit (belum termasuk PNBP)',
			tax: 'Taxeable',
		},
		{
			productService: null,
			model: null,
			volume: '1.000.001',
			price: 'Rp2.050',
			charge: 'Per hit (belum termasuk PNBP)',
			tax: 'Taxeable',
		},
		{
			productService: 'E-materai (Electronic Stamp Duty)',
			model: 'Tier',
			volume: '1 - 1,000',
			price: 'Rp12.700',
			charge: 'Per materai',
			tax: 'Free',
		},
		{
			productService: null,
			model: null,
			volume: '1,001',
			price: 'Rp12.370',
			charge: 'Per materai',
			tax: 'Free',
		},
		{
			productService: null,
			model: null,
			volume: '1,001',
			price: 'Rp12.370',
			charge: 'Per materai',
			tax: 'Free',
		},
		{
			productService: 'Liveness',
			model: 'Tier',
			volume: '1 - 50.000',
			price: 'Rp420',
			charge: 'Per hit',
			tax: 'Taxeable',
		},
		{
			productService: null,
			model: null,
			volume: '50.001',
			price: 'Rp374',
			charge: 'Per hit',
			tax: 'Taxeable',
		},
		{
			productService: 'OCR KTP (ID Card Optical Character Recognition)',
			model: 'As you go',
			volume: '1',
			price: 'Rp374',
			charge: 'Per hit',
			tax: 'Taxeable',
		},
		{
			productService: 'OCR STNK (Vehicle Registration Certificate OCR)',
			model: 'Tier',
			volume: '1 - 50,000',
			price: 'Rp525',
			charge: 'Per hit',
			tax: 'Taxeable',
		},
		{
			productService: null,
			model: null,
			volume: '50,001 - 100,000',
			price: 'Rp500',
			charge: 'Per hit',
			tax: 'Taxeable',
		},
		{
			productService: null,
			model: null,
			volume: '100,001 - 500,000',
			price: 'Rp475',
			charge: 'Per hit',
			tax: 'Taxeable',
		},
		{
			productService: null,
			model: null,
			volume: '500,001 - 1,000,000',
			price: 'Rp450',
			charge: 'Per hit',
			tax: 'Taxeable',
		},
		{
			productService: null,
			model: null,
			volume: '1,000,001',
			price: 'Rp400',
			charge: 'Per hit',
			tax: 'Taxeable',
		},
		{
			productService: 'PNBP (Non-Tax State Revenue)',
			model: 'As you go',
			volume: '1',
			price: 'Rp4.000',
			charge: 'Per hit e-KYC',
			tax: 'Free',
		},
	],
	tabsData: [
		{
			key: 'canvasSigning',
			label: 'Canvas Signing',
			children:
				'A company wants employees to sign non-disclosure agreements digitally. The HR team sends out 100 documents for signing, and each is completed with Canvas Signing. Since the service is free, there is no cost, making it ideal for high-volume internal document signing.',
		},
		{
			key: 'digitalSignature',
			label: 'Digital Signature',
			children:
				'A client needs to verify official documents like contracts, which require legally binding signatures. Each time a user signs, Rp660 is billed. For 20 signed contracts in a month, the charge would be 20 x Rp660 = Rp13,200, plus applicable taxes.',
		},
		{
			key: 'ekyc',
			label: 'E-KYC',
			children:
				'A company (bank) uses E-KYC for verifying user identities during account creation. They conduct 100,000 verifications in a month, resulting in a cost of 100,000 x Rp2,380 = Rp238,000,000, plus tax. For over 1 million verifications, the price per hit reduces to Rp2,050, helping to control costs as volume grows.',
		},
		{
			key: 'ematerai',
			label: 'E-Materai',
			children:
				'A legal firm electronically stamps 1,500 documents monthly. For the first 1,000 stamps, the cost is 1,000 x Rp12,700 = Rp12,700,000. The remaining 500 stamps are billed at Rp12,370 each, totaling an additional 500 x Rp12,370 = Rp6,185,000. The monthly charge amounts to Rp18,885,000 with no tax.',
		},
		{
			key: 'liveness',
			label: 'Liveness',
			children:
				'A banking app uses liveness detection to confirm a user for a loan service. For 60,000 checks, the first 50,000 are billed at Rp420 per hit (50,000 x Rp420 = Rp21,000,000), and the remaining 10,000 at Rp374 per hit (10,000 x Rp374 = Rp3,740,000), totaling Rp24,740,000, plus tax.',
		},
		{
			key: 'ocrKtp',
			label: 'OCR KTP',
			children:
				'A fintech platform requires user ID information for registration. OCR KTP is used to extract details from scanned ID cards. If 10,000 IDs are processed in a month, the cost is 10,000 x Rp374 = Rp3,740,000, plus applicable tax.',
		},
		{
			key: 'ocrStnk',
			label: 'OCR STNK',
			children: (
				<div>
					<p>
						A vehicle rental company uses OCR STNK to validate vehicle
						registrations. For 120,000 scans in a month, the breakdown is as
						follows:
					</p>
					<ul className="list-disc">
						<li className="ml-4">
							First 50,000 hits at Rp525 per hit: 50,000 x Rp525 = Rp26,250,000
						</li>
						<li className="ml-4">
							Next 50,000 hits at Rp500 per hit: 50,000 x Rp500 = Rp25,000,000
						</li>
						<li className="ml-4">
							Remaining 20,000 hits at Rp475 per hit: 20,000 x Rp475 =
							Rp9,500,000
						</li>
					</ul>
				</div>
			),
		},
		{
			key: 'pnbp',
			label: 'PNBP',
			children:
				'An insurance company needs PNBP services to process certain government-related transactions. For 5,000 transactions, the cost is 5,000 x Rp4,000 = Rp20,000,000, with no tax applied.',
		},
	],
};

export const SQECC = {
	tableColumns: [
		{
			title: 'Produk / Service',
			dataIndex: 'productService',
		},
		{ title: 'Model', dataIndex: 'model' },
		{ title: 'Volume', dataIndex: 'volume' },
		{ title: 'Price', dataIndex: 'price' },
		{ title: 'Charge', dataIndex: 'charge' },
		{ title: 'Tax', dataIndex: 'tax' },
	],
	tableData: [
		{
			productService: 'Analytic Dashboard',
			model: 'Flat',
			volume: '1',
			price: 'Rp1.000.000',
			charge: 'Per-month',
			tax: 'Taxeable',
		},
		{
			productService: 'Text Support (Customer Chat Support)',
			model: 'As you go',
			volume: '1',
			price: 'Rp1.500.000',
			charge: 'Per-agent / month',
			tax: 'Taxeable',
		},
		{
			productService: 'Video Call',
			model: 'As you go',
			volume: '1',
			price: 'Rp1.400',
			charge: 'Per-agent / month',
			tax: 'Taxeable',
		},
	],
	tabsData: [
		{
			key: 'analyticDashboard',
			label: 'Analytic Dashboard',
			children:
				'When a business unit needs analytics to monitor the usage of services on SQECC, such as Text and Video Call, a flat rate of Rp1,000,000 per month will be applied.',
		},
		{
			key: 'textSupport',
			label: 'Text Support',
			children: (
				<div>
					A business unit uses the SQECC Text Support service to manage customer
					support through their Contact Center. The service is billed per agent
					each month. Example: In February 2024, Bank Sinarmas assigns two
					agents to handle customer support. Regardless of the number of
					conversations, the Text Support service will charge based on the
					number of agents involved that month. Calculation: 2 agents x
					Rp1,500,000 = Rp3,000,000. Bank Sinarmas will receive a bill for
					Rp3,000,000.
					<Paragraph weight="bold" className="mt-4">
						SQECC – TEXT IMPORTANT NOTES:
					</Paragraph>
					<ul className="list-[lower-alpha]">
						<li className="ml-8">Billing is calculated per agent per month.</li>
						<li className="ml-8">
							Agents are uniquely identified by email rather than the individual
							user.
						</li>
						<li className="ml-8">
							If two agents use a shared account or the same email, the system
							will count this as one agent.
						</li>
						<li className="ml-8">
							If two agents operate under separate email accounts, the system
							will count them as two agents.
						</li>
						<li className="ml-8">
							Email is the unique identifier we use to track each active agent
							in our system.
						</li>
						<li className="ml-8">
							The total number of conversations does not affect the bill, as the
							charge is per agent.
						</li>
					</ul>
				</div>
			),
		},
		{
			key: 'videoCall',
			label: 'Video Call',
			children: (
				<div>
					A business unit uses the SQECC Video Call service through their
					Contact Center to support customers. The service is billed per minute
					of call time. Example: In March 2024, Bank Sinarmas records a total
					Video Call duration of 40 hours, 30 minutes, and 40 seconds. The Video
					Call billing system will round up the duration, making it 40 hours and
					31 minutes, or 2,431 minutes. Calculation: Rp1,400 x 2,431 minutes =
					Rp3,403,400. Bank Sinarmas will receive a bill for Rp3,403,400.
					<Paragraph weight="bold" className="mt-4">
						SQECC – VIDEO CALL IMPORTANT NOTES:
					</Paragraph>
					<ul className="list-[lower-alpha]">
						<li className="ml-8">Billing is calculated per minute.</li>
						<li className="ml-8">
							The call duration is counted from when one party (agent or
							customer) initiates the session.
						</li>
						<li className="ml-8">
							The Video Call session ends when the agent terminates or ends the
							session.
						</li>
						<li className="ml-8">
							The Video Call billing system will round up the duration.
						</li>
					</ul>
				</div>
			),
		},
	],
};

export const SQEMP = {
	tableColumns: [
		{
			title: 'Produk / Service',
			dataIndex: 'productService',
		},
		{ title: 'Model', dataIndex: 'model' },
		{ title: 'Volume', dataIndex: 'volume' },
		{ title: 'Price', dataIndex: 'price' },
		{ title: 'Charge', dataIndex: 'charge' },
		{ title: 'Tax', dataIndex: 'tax' },
	],
	tableData: [
		{
			productService: 'App / Web Push Notification',
			model: 'Multiplier',
			volume: '100.000',
			price: 'Rp1.200.000',
			charge: 'Per 100.000 subscriber /  month',
			tax: 'Taxeable',
		},
		{
			productService: 'Email',
			model: 'Multiplier',
			volume: '1.000',
			price: 'Rp5.000',
			charge: 'Per 1.000 email',
			tax: 'Taxeable',
		},
		{
			productService: 'SMS Push Notification',
			model: 'As you go',
			volume: '1',
			price: 'Rp535',
			charge: 'Per SMS',
			tax: 'Taxeable',
		},
		{
			productService: 'WhatsApp Messenger Authentucation',
			model: 'As you go',
			volume: '1',
			price: 'Rp428',
			charge: 'Per 1x24 hours conversation via WhatsApp',
			tax: 'Taxeable',
		},
		{
			productService: 'WhatsApp Messenger Marketing',
			model: 'As you go',
			volume: '1',
			price: 'Rp587',
			charge: 'Per 1x24 hours conversation via WhatsApp',
			tax: 'Taxeable',
		},
		{
			productService: 'WhatsApp Messenger Service',
			model: 'As you go',
			volume: '1',
			price: 'Rp271',
			charge: 'Per 1x24 hours conversation via WhatsApp',
			tax: 'Taxeable',
		},
		{
			productService: 'WhatsApp Messenger Utility',
			model: 'As you go',
			volume: '1',
			price: 'Rp286',
			charge: 'Per 1x24 hours conversation via WhatsApp',
			tax: 'Taxeable',
		},
	],
	tabsData: [
		{
			key: 'appNotification',
			label: 'App/Web Push Notification',
			children: (
				<div>
					The App/Web Push Notification service charges Rp1,200,000 for every
					100,000 push notifications sent. For example, if Bank Sinarmas sends
					125,000 notifications in October 2024, the calculation is:
					<ul className="list-disc">
						<li className="ml-8">First 100,000 notifications = Rp1,200,000</li>
						<li className="ml-8">
							Remaining 25,000 notifications = Rp1,200,000 (charged as another
							full quota).
						</li>
					</ul>
					The total charge is Rp2,400,000. This multiplier model charges in full
					increments of 100,000 notifications, meaning even a single
					notification beyond the quota will incur an additional Rp1,200,000
					charge.
				</div>
			),
		},
		{
			key: 'email',
			label: 'Email',
			children: (
				<div>
					The Email service operates similarly to App/Web Push Notifications,
					but charges Rp5,000 for every 1,000 emails sent, regardless of whether
					the email is successfully delivered. For instance, if 7,500 emails are
					sent in a month:
					<ul className="list-disc">
						<li className="ml-8">First 7,000 emails (7 x 1,000) = Rp35,000</li>
						<li className="ml-8">
							Remaining 500 emails = Rp5,000 (charged as a full 1,000 emails).
						</li>
					</ul>
					The total charge is Rp40,000, with the multiplier model applying in
					full increments of 1,000 emails.
				</div>
			),
		},
		{
			key: 'sms',
			label: 'SMS Push Notification',
			children:
				'The SMS Push Notification service is as-you-go, charging Rp535 per SMS sent, regardless of delivery status. For example, if a client sends 5,000 SMS messages in a month, the total cost is 5,000 x Rp535 = Rp2,675,000. There are no additional fees or quotas; billing is purely based on the number of SMS sent.',
		},
		{
			key: 'wa',
			label: 'WA Authentication',
			children: (
				<div>
					This service, often used for OTPs, charges Rp428 per user per 24-hour
					session, regardless of the number of messages sent to the same user
					during that time. For example:
					<ul className="list-disc">
						<li className="ml-8">
							User-A receives OTPs on January 14th at 08:30 a.m., 1:00 p.m., and
							9:20 p.m. = Rp428.
						</li>
						<li className="ml-8">
							User-B receives OTPs on January 14th at 9:00 a.m. and January 15th
							at 9:05 a.m. = Rp428 for each day (Rp856 total).
						</li>
					</ul>
					The total charge is Rp1,284, as the system charges per 24-hour window
					per user, even if multiple messages are sent within that period.
				</div>
			),
		},
		{
			key: 'waMarketing',
			label: 'WA Marketing',
			children: (
				<div>
					This service, commonly used for broadcasting marketing or promotional
					messages, charges Rp587 per user per 24-hour session, regardless of
					the number of messages sent to the same user during that period. For
					example:
					<ul className="list-disc">
						<li className="ml-8">
							User-A receives promotional messages on January 14th at 08:30
							a.m., 1:00 p.m., and 9:20 p.m. = Rp587.
						</li>
						<li className="ml-8">
							User-B receives marketing messages on January 14th at 9:00 a.m.
							and January 15th at 9:05 a.m. = Rp587 for each day (Rp1,174
							total).
						</li>
					</ul>
					The total charge is Rp1,761, as the system bills per 24-hour window
					per user, even if multiple marketing messages are sent within that
					timeframe.
				</div>
			),
		},
		{
			key: 'waService',
			label: 'WA Service',
			children: (
				<div>
					This service, typically used for customer service interactions,
					charges Rp271 per user per 24-hour session, but only when a staff
					member replies to the user. For example:
					<ul className="list-disc">
						<li className="ml-8">
							User-A sends several inquiries, and staff replies on January 14th
							at 08:30 a.m., 1:00 p.m., and 9:20 p.m. = Rp271 (one charge for
							the 24-hour session).
						</li>
						<li className="ml-8">
							User-B sends inquiries and receives a staff reply on January 14th
							at 9:00 a.m., and again on January 15th at 9:05 a.m. = Rp271 for
							each day (Rp542 total).
						</li>
					</ul>
					The total charge is Rp813, as the system charges based on when staff
					replies occur within 24-hour windows, regardless of how many messages
					are sent by the user or staff during that time.
				</div>
			),
		},
		{
			key: 'waUtility',
			label: 'WA Utility',
			children: (
				<div>
					This service, typically used for automated notifications like delivery
					updates, charges Rp286 per user per 24-hour session, regardless of how
					many notifications are sent to the same user during that time. For
					example:
					<ul className="list-disc">
						<li className="ml-8">
							User-A receives system-generated updates on January 14th at 08:30
							a.m., 1:00 p.m., and 9:20 p.m. = Rp286.
						</li>
						<li className="ml-8">
							User-B receives notifications on January 14th at 9:00 a.m. and
							January 15th at 9:05 a.m. = Rp286 for each day (Rp572 total).
						</li>
					</ul>
					The total charge is Rp858, as the system bills per 24-hour window per
					user, even if multiple automated notifications are sent within that
					timeframe.
				</div>
			),
		},
	],
};

export const SQEID = {
	tableColumns: [
		{
			title: 'Produk / Service',
			dataIndex: 'productService',
		},
		{ title: 'Model', dataIndex: 'model' },
		{ title: 'Volume', dataIndex: 'volume' },
		{ title: 'Price', dataIndex: 'price' },
		{ title: 'Charge', dataIndex: 'charge' },
		{ title: 'Tax', dataIndex: 'tax' },
	],
	tableData: [
		{
			productService: 'Authentication',
			model: 'As you go',
			volume: '1',
			price: 'Rp250',
			charge: 'Per-month active user',
			tax: 'Taxeable',
		},
		{
			productService: 'SSo',
			model: 'As you go',
			volume: '1',
			price: 'Rp2.400',
			charge: 'Per-first login',
			tax: 'Taxeable',
		},
	],
	tabsData: [
		{
			key: 'auth',
			label: 'Authentication',
			children: (
				<div>
					We charge <span className="font-bold">Rp250</span> per active user for
					<span className="font-bold"> every 30-day</span> calendar period based
					on their first login using SQEID authentication. For example, if
					User-A login to the Garasi App for the first time on January 15th,
					they are marked as active until February 14th. <br />
					<br />
					Any session ends, logouts, or re-logins during this period will not
					incur additional charges. However, if User-A login again on February
					16th, after the 30-day period has ended, a new charge will apply. The
					users are billed only once per active period, regardless of the number
					of logins or sessions.
				</div>
			),
		},
		{
			key: 'sso',
			label: 'SSo',
			children: (
				<div>
					SSO billing occurs when a user
					<span className="font-bold">
						{' '}
						login to a new platform for the first time{' '}
					</span>
					using SQEID-SSO, such as SimasID. For instance, when User-A activates
					their SSO on the Garasi App, no charge is applied. However, if they
					log into another platform, like Nanovest, for the first time using
					SimasID, the platform is charged{' '}
					<span className="font-bold">Rp2,400</span>. <br />
					<br />
					This fee applies only once for the user’s initial login to each
					platform;{' '}
					<span className="font-bold">subsequent logins are free</span>. SSO
					simplifies access across multiple platforms while ensuring fair,
					one-time charges for unique user logins.
				</div>
			),
		},
	],
};

export const SQEPORTAL = {
	tableColumns: [
		{
			title: 'Produk / Service',
			dataIndex: 'productService',
		},
		{ title: 'Model', dataIndex: 'model' },
		{ title: 'Volume', dataIndex: 'volume' },
		{ title: 'Price', dataIndex: 'price' },
		{ title: 'Charge', dataIndex: 'charge' },
		{ title: 'Tax', dataIndex: 'tax' },
	],
	tableData: [
		{
			productService: 'Analytic Dashboard',
			model: 'Flat',
			volume: '1',
			price: 'Rp1.000.000',
			charge: 'Per-month',
			tax: 'Taxeable',
		},
	],
	tabsData: [
		{
			key: 'portal',
			label: 'Portal',
			children: (
				<Paragraph className="mt-3">
					The Portal Analytic Dashboard provides business units with easy access
					to monitor the usage of services they have subscribed to. For a flat
					rate of <span className="font-bold">Rp1,000,000 per month</span>,
					businesses can gain insights into service consumption, allowing them
					to perform day-to-day tracking and make data-driven decisions. This
					tool is particularly beneficial for keeping tabs on SQE service usage
					in real time, ensuring transparency and efficient management.
				</Paragraph>
			),
		},
	],
};

export const LIST = [
	{
		name: 'SQEKYC',
		description: 'Know your customer & Canvas Signing',
		image: '/assets/images/sqekyc.png',
		data: SQEKYC,
	},
	{
		name: 'SQECC',
		description: 'Contact Center',
		image: '/assets/images/sqecc.png',
		data: SQECC,
	},
	{
		name: 'SQEMP',
		description: 'Messaging Platform',
		image: '/assets/images/sqemp.png',
		data: SQEMP,
	},
	{
		name: 'SQEID',
		description: 'Centralized Account for Single Sign On',
		image: '/assets/images/sqeid.png',
		data: SQEID,
	},
	{
		name: 'SQEPortal',
		description: 'Analytic Dashboard',
		image: '/assets/images/sqeportal.png',
		data: SQEPORTAL,
	},
];
