import type { SidebarItemProps } from '@squantumengine/horizon';
import { Icon } from '@squantumengine/horizon';

export const ALL_ROUTE_PATH_MAPPING: { [key: string]: string } = {
	beranda: '/',
	'activity-log': '/activity-log',
	'invoice-management': '/invoice-management',
	'invoice-management-approval': '/invoice-management/approval',
	'admin-dashboard': '/',
	'dashboard-kyc': '/dashboard/know-your-customer',
};
export const ROLE_ALLOWED_PATHS: {
	[key: string]: { path: string; id: string }[];
} = {
	manager: [
		{
			path: '/',
			id: 'beranda',
		},
		{
			path: '/contact-center',
			id: 'beranda',
		},
		{
			path: '/messaging-platform',
			id: 'beranda',
		},
		{
			path: '/know-your-customer',
			id: 'beranda',
		},
		{
			path: '/identity',
			id: 'beranda',
		},
		{
			path: '/invoice-management',
			id: 'invoice-management',
		},
		{
			path: '/invoice-management/preview/:organisationId',
			id: 'invoice-management',
		},
		{
			path: '/invoice-management/approval',
			id: 'invoice-management-approval',
		},
		{
			path: '/invoice-management/approval/:reportId',
			id: 'invoice-management-approval',
		},
		{
			path: '/invoice-management/approval/log/:reportId',
			id: 'invoice-management-approval',
		},
		{
			path: '/business-unit/add',
			id: 'invoice-management',
		},
		{
			path: '/business-unit/:organisationId/edit',
			id: 'invoice-management',
		},
		{
			path: '/business-unit/:organisationId',
			id: 'invoice-management',
		},
		{
			path: '/price/general',
			id: 'invoice-management',
		},
		{
			path: '/price/general/edit',
			id: 'invoice-management',
		},
		{
			path: '/price/custom/:organisationId',
			id: 'invoice-management',
		},
		{
			path: '/price/custom/:organisationId/edit',
			id: 'invoice-management',
		},
		{
			path: '/activity-log',
			id: 'activity-log',
		},
		{
			path: '/pricing-guideline',
			id: 'pricing-guideline',
		},
	],
	pengelola: [
		{
			path: '/',
			id: 'beranda',
		},
		{
			path: '/contact-center',
			id: 'beranda',
		},
		{
			path: '/messaging-platform',
			id: 'beranda',
		},
		{
			path: '/know-your-customer',
			id: 'beranda',
		},
		{
			path: '/identity',
			id: 'beranda',
		},
		{
			path: '/dashboard/know-your-customer',
			id: 'dashboard-kyc',
		},
		{
			path: '/dashboard/know-your-customer/signer/:signerId',
			id: 'dashboard-kyc',
		},
		{
			path: '/dashboard/know-your-customer/signer/activity-log',
			id: 'dashboard-kyc',
		},
		{
			path: '/dashboard/know-your-customer/send-reminder',
			id: 'dashboard-kyc',
		},
		{
			path: '/approval/:reportId',
			id: 'beranda',
		},
		{
			path: '/approval/log/:reportId',
			id: 'beranda',
		},
		{
			path: '/pricing-guideline',
			id: 'pricing-guideline',
		},
	],
	admin: [
		{
			path: '/admin',
			id: 'admin-dashboard',
		},
	],
	internal_user: [
		{
			path: '/',
			id: 'beranda',
		},
		{
			path: '/contact-center',
			id: 'beranda',
		},
		{
			path: '/messaging-platform',
			id: 'beranda',
		},
		{
			path: '/know-your-customer',
			id: 'beranda',
		},
		{
			path: '/identity',
			id: 'beranda',
		},
		{
			path: '/pricing-guideline',
			id: 'pricing-guideline',
		},
	],
	gmra_editor: [
		{
			path: '/',
			id: 'beranda',
		},
		{
			path: '/contact-center',
			id: 'beranda',
		},
		{
			path: '/messaging-platform',
			id: 'beranda',
		},
		{
			path: '/know-your-customer',
			id: 'beranda',
		},
		{
			path: '/identity',
			id: 'beranda',
		},
		{
			path: '/dashboard/know-your-customer',
			id: 'dashboard-kyc',
		},
		{
			path: '/dashboard/know-your-customer/signer/:signerId',
			id: 'dashboard-kyc',
		},
		{
			path: '/dashboard/know-your-customer/signer/activity-log',
			id: 'dashboard-kyc',
		},
		{
			path: '/dashboard/know-your-customer/send-reminder',
			id: 'dashboard-kyc',
		},
		{
			path: '/pricing-guideline',
			id: 'pricing-guideline',
		},
	],
	gmra_viewer: [
		{
			path: '/',
			id: 'beranda',
		},
		{
			path: '/contact-center',
			id: 'beranda',
		},
		{
			path: '/messaging-platform',
			id: 'beranda',
		},
		{
			path: '/know-your-customer',
			id: 'beranda',
		},
		{
			path: '/identity',
			id: 'beranda',
		},
		{
			path: '/dashboard/know-your-customer',
			id: 'dashboard-kyc',
		},
		{
			path: '/dashboard/know-your-customer/signer/:signerId',
			id: 'dashboard-kyc',
		},
		{
			path: '/dashboard/know-your-customer/signer/activity-log',
			id: 'dashboard-kyc',
		},
		{
			path: '/dashboard/know-your-customer/send-reminder',
			id: 'dashboard-kyc',
		},
		{
			path: '/pricing-guideline',
			id: 'pricing-guideline',
		},
	],
};
export const ROLE_NAVIGATION: { [key: string]: SidebarItemProps[] } = {
	manager: [
		{
			icon: <Icon name="home" size="md" />,
			id: 'beranda',
			text: 'Beranda',
			url: '/',
		},
		{
			icon: <Icon name="file-invoice-dollar" size="md" />,
			id: 'dashboard',
			text: 'Dashboard',
			children: [
				{
					id: 'invoice-management',
					text: 'Invoice',
					url: '/invoice-management',
				},
				{
					id: 'invoice-management-approval',
					text: 'Report',
					url: '/invoice-management/approval',
				},
			],
		},
		{
			icon: <Icon name="history" size="md" />,
			id: 'activity-log',
			text: 'Aktivitas ',
			url: '/activity-log',
		},
	],
	admin: [
		{
			icon: <Icon name="users" size="md" />,
			id: 'admin-dashboard',
			text: 'Pengaturan',
			url: '/admin',
		},
	],
	pengelola: [
		{
			icon: <Icon name="home" size="md" />,
			id: 'beranda',
			text: 'Beranda',
			url: '/',
		},
		{
			icon: <Icon name="id-badge" size="md" />,
			id: 'dashboard-kyc',
			text: 'Dashboard KYC',
			url: '/dashboard/know-your-customer',
		},
	],
	internal_user: [
		{
			icon: <Icon name="home" size="md" />,
			id: 'beranda',
			text: 'Beranda',
			url: '/',
		},
	],
	gmra_editor: [
		{
			icon: <Icon name="home" size="md" />,
			id: 'beranda',
			text: 'Beranda',
			url: '/',
		},
		{
			icon: <Icon name="id-badge" size="md" />,
			id: 'dashboard-kyc',
			text: 'Dashboard KYC',
			url: '/dashboard/know-your-customer',
		},
	],
	gmra_viewer: [
		{
			icon: <Icon name="home" size="md" />,
			id: 'beranda',
			text: 'Beranda',
			url: '/',
		},
		{
			icon: <Icon name="id-badge" size="md" />,
			id: 'dashboard-kyc',
			text: 'Dashboard KYC',
			url: '/dashboard/know-your-customer',
		},
	],
};
