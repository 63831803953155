import { useEffect, useState, lazy, Suspense } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Paragraph, Title, Tabs, Button, Icon } from '@squantumengine/horizon';
import { GENERAL_ERROR_MESSAGE } from 'common/constants';
import dayjs from 'dayjs';
import EmptyDocument from 'features/dashboard/know-your-customer/empty-document';
import type { SignerStatus } from 'features/dashboard/know-your-customer/kyc-dashboard.interfaces';
import { useProfileStore } from 'common/stores/profile';
import SectionHeader from 'common/components/section-header';
import { generateInitial } from 'common/utils/initial';
import ToastMessage from 'common/utils/toast-message';
import { camelize } from 'common/utils/transformer';
import { generateQueryString } from 'common/utils/url';
import useDidMount from 'common/hooks/use-did-mount';
import useModal from 'common/hooks/use-modal';
import usePagination from 'common/hooks/use-pagination';
import useSort from 'common/hooks/use-sort';
import {
	getNonWeblinkSigner,
	getSignerUserDetail,
	getWeblinkSigner,
	updateSignersEkyc,
} from 'services/usage';
import { ALL_SIGNER_STATUS } from './signer-detail.constants';
import type {
	SignerUserDetail,
	WeblinkSort,
	PutUpdateSignerEkycPayload,
} from './signer-detail.interfaces';
import Filter from './table/filter';
import NonWeblinkTable from './table/non-weblink';
import type { NonWeblinkList } from './table/non-weblink/non-weblink.interfaces';
import WeblinkTable from './table/weblink';
import type { WeblinkList } from './table/weblink/weblink.interfaces';
import UserDetailSkeleton from './user-detail.skeleton';

const UpdateEkycModal = lazy(
	() => import(/* webpackChunkName: "updateEkycModal" */ './update-ekyc-modal')
);

const DetailSigner = () => {
	const checkPermission = useProfileStore((state) => state.checkPermission);
	const navigate = useNavigate();
	const params = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const updateEkyModal = useModal();
	const weblinkPagination = usePagination();
	const nonWeblinkPagination = usePagination();
	const sortWeblink = useSort({
		by: 'weblink_created_at',
		order: 'desc',
	});
	const sortNonWeblink = useSort({
		by: 'contract_created_at',
		order: 'desc',
	});
	const start = searchParams.get('start');
	const end = searchParams.get('end');
	const currentTabQuery = searchParams.get('currentTab');

	const [weblinkList, setWeblinkList] = useState<WeblinkList[]>([]);
	const [searchWeblink, setSearchWeblink] = useState('');
	const [weblinkSigningStatus, setWeblinkSigningStatus] =
		useState<SignerStatus[]>(ALL_SIGNER_STATUS);
	const [nonWeblinkList, setNonWeblinkList] = useState<NonWeblinkList[]>([]);
	const [searchNonWeblink, setSearchNonWeblink] = useState('');
	const [nonWeblinkSigningStatus, setNonWeblinkSigningStatus] =
		useState<SignerStatus[]>(ALL_SIGNER_STATUS);
	const [refUserId, setRefUserId] = useState('');
	const [detailUser, setDetailUser] = useState<SignerUserDetail | null>(null);
	const [currentTab, setCurrentTab] = useState('weblink');

	const {
		isFetching: isGetWeblinkSignerLoading,
		refetch: refetchWeblinkSigner,
	} = useQuery({
		queryKey: [
			'getWeblinkSigner',
			searchWeblink,
			refUserId,
			params.signerId,
			sortWeblink.sort,
			weblinkPagination.pageSize,
			weblinkPagination.currentPage,
		],
		enabled: !!refUserId,
		refetchOnWindowFocus: true,
		queryFn: () =>
			getWeblinkSigner({
				id: refUserId,
				params: {
					contract_created_at_gte: dayjs(start).format('YYYY-MM-DD'),
					contract_created_at_lte: dayjs(end).format('YYYY-MM-DD'),
					page: weblinkPagination.currentPage,
					limit: weblinkPagination.pageSize,
					weblink_id: searchWeblink,
					signing_status: weblinkSigningStatus.join(),
					sort_by: `${sortWeblink.sort.by}:${sortWeblink.sort.order}`,
				},
			}),
		onSuccess: (response) => {
			const data = response.data.items ?? [];
			const total = response?.data?.pagination?.totalItems ?? 0;
			const sanitizedResponse = camelize(data) as WeblinkList[];

			setWeblinkList(
				sanitizedResponse.map((item) => {
					return {
						...item,
						key: item.weblinkId,
					};
				})
			);
			weblinkPagination.setTotalCount(total);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const {
		isFetching: isGetNonWeblinkSignerLoading,
		refetch: refetchNonWeblinkSigner,
	} = useQuery({
		queryKey: [
			'getNonWeblinkSigner',
			searchNonWeblink,
			params.signerId,
			sortNonWeblink.sort,
			nonWeblinkPagination.pageSize,
			nonWeblinkPagination.currentPage,
		],
		enabled: !!params.signerId,
		refetchOnWindowFocus: true,
		queryFn: () =>
			getNonWeblinkSigner({
				id: params.signerId,
				params: {
					contract_created_at_gte: dayjs(start).format('YYYY-MM-DD'),
					contract_created_at_lte: dayjs(end).format('YYYY-MM-DD'),
					page: nonWeblinkPagination.currentPage,
					limit: nonWeblinkPagination.pageSize,
					ref_contract_id_or_contract_name: searchNonWeblink,
					signing_status: nonWeblinkSigningStatus.join(),
					sort_by: `${sortNonWeblink.sort.by}:${sortNonWeblink.sort.order}`,
				},
			}),
		onSuccess: (response) => {
			const data = response.data.items ?? [];
			const total = response?.data?.pagination?.totalItems ?? 0;
			const sanitizedResponse = camelize(data) as NonWeblinkList[];

			setNonWeblinkList(
				sanitizedResponse.map((item) => {
					return {
						...item,
						key: item.contractId,
					};
				})
			);
			nonWeblinkPagination.setTotalCount(total);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const { isLoading: isUserDetailLoading, refetch: refetchUserDetail } =
		useQuery({
			queryKey: ['getUserDetail'],
			enabled: !!params.signerId,
			refetchOnWindowFocus: true,
			queryFn: () => getSignerUserDetail(params.signerId),
			onSuccess: (response) => {
				const data = response.data ?? null;
				const sanitizedResponse = camelize(data) as SignerUserDetail;

				setDetailUser(sanitizedResponse);
			},
			onError: () => {
				ToastMessage({
					type: 'error',
					label: GENERAL_ERROR_MESSAGE,
				});
			},
		});

	const onApplyWeblinkFilter = () => {
		refetchWeblinkSigner();
	};

	const onChangeWeblinkStatus = (status: SignerStatus[]) => {
		setWeblinkSigningStatus(status);
	};

	const onSearchWeblink = (value: string) => {
		setSearchWeblink(value);
		weblinkPagination.setCurrentPage(1);
	};

	const onSortWeblink = (sort: WeblinkSort) => {
		sortWeblink.setSort(sort);
	};

	const onApplyNonWeblinkFilter = () => {
		refetchNonWeblinkSigner();
	};

	const onChangeNonWeblinkStatus = (status: SignerStatus[]) => {
		setNonWeblinkSigningStatus(status);
	};

	const onSearchNonWeblink = (value: string) => {
		setSearchNonWeblink(value);
		nonWeblinkPagination.setCurrentPage(1);
	};

	const onSortNonWeblink = (sort: WeblinkSort) => {
		sortNonWeblink.setSort(sort);
	};

	const onChangeTab = (key: string) => {
		setCurrentTab(key);
	};

	const updateEkyc = useMutation(updateSignersEkyc, {
		onSuccess: () => {
			updateEkyModal.closeModal();
			refetchWeblinkSigner();
			refetchNonWeblinkSigner();
			refetchUserDetail();
			ToastMessage({
				type: 'success',
				label: 'Data berhasil diperbarui.',
			});
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: 'Data gagal diperbarui.',
			});
		},
	});

	const onUpdateEkyc = (id: string, payload: PutUpdateSignerEkycPayload) => {
		if (!id) return;

		updateEkyc.mutate({
			id,
			payload,
		});
	};

	const isShowWeblinkList =
		isGetWeblinkSignerLoading ||
		(!isGetWeblinkSignerLoading && weblinkList.length > 0);
	const isShowNonWeblinkList =
		isGetNonWeblinkSignerLoading ||
		(!isGetNonWeblinkSignerLoading && nonWeblinkList.length > 0);
	const signersDetail = {
		name: detailUser?.userName ?? '',
		email: detailUser?.userEmail ?? '',
		phone: detailUser?.userPhone?.replace(/^\+62/, '') ?? '',
	};
	const isShowEditEkycProfile = useFeatureIsOn('gmra-edit-signer-ekyc');
	const showEditKyc =
		isShowEditEkycProfile && checkPermission('ekyc-dashboard', 'update-signer');
	useEffect(() => {
		const refUserIdQuery = searchParams.get('refUserId');

		if (
			!start ||
			!end ||
			!dayjs(start, 'YYYY-MM-DD', true).isValid() ||
			!dayjs(end, 'YYYY-MM-DD', true).isValid() ||
			!refUserIdQuery
		)
			return navigate(-1);

		const searchWeblinkQuery = searchParams.get('searchWeblink') ?? '';
		const searchNonWeblinkQuery = searchParams.get('searchNonWeblink') ?? '';
		const weblinkStatusQuery = searchParams.get('weblinkStatus') ?? '';
		const nonWeblinkStatusQuery = searchParams.get('nonWeblinkStatus') ?? '';

		const weblinkStatusQueryArr = weblinkStatusQuery.split(
			','
		) as typeof ALL_SIGNER_STATUS;
		const nonWeblinkStatusQueryArr = nonWeblinkStatusQuery.split(
			','
		) as typeof ALL_SIGNER_STATUS;
		const statusWeblink = weblinkStatusQuery
			? weblinkStatusQueryArr.filter((status) =>
					ALL_SIGNER_STATUS.includes(status)
			  )
			: ALL_SIGNER_STATUS;

		const statusNonWeblink = nonWeblinkStatusQuery
			? nonWeblinkStatusQueryArr.filter((status) =>
					ALL_SIGNER_STATUS.includes(status)
			  )
			: ALL_SIGNER_STATUS;

		setWeblinkSigningStatus(statusWeblink);
		setNonWeblinkSigningStatus(statusNonWeblink);
		setCurrentTab(currentTabQuery ?? 'weblink');
		setSearchNonWeblink(searchNonWeblinkQuery);
		setSearchWeblink(searchWeblinkQuery);
		setRefUserId(refUserIdQuery);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useDidMount(() => {
		const params = {
			searchNonWeblink,
			searchWeblink,
			start,
			end,
			weblinkStatus: weblinkSigningStatus,
			nonWeblinkStatus: nonWeblinkSigningStatus,
			currentTab,
			refUserId,
		};

		const sanitizedParams = generateQueryString(params);
		setSearchParams(sanitizedParams, { replace: true });
	}, [
		searchNonWeblink,
		searchWeblink,
		start,
		end,
		weblinkSigningStatus,
		nonWeblinkSigningStatus,
		currentTab,
		refUserId,
	]);

	return (
		<>
			<div className="pt-8">
				<SectionHeader
					containerClass="mb-6"
					title="Data Penandatangan"
					backHandler={() => navigate(-1)}
				/>
			</div>
			<div className="my-6 bg-neutral-0 rounded-lg shadow-base px-6 py-5">
				{isUserDetailLoading && <UserDetailSkeleton />}
				{!isUserDetailLoading && (
					<div className="flex justify-between items-center">
						<div className="flex gap-4 items-center">
							<div className="w-[64px] h-[64px] p-1">
								<div className="w-[56px] h-[56px] bg-blue-500 rounded-full flex items-center justify-center text-neutral-0">
									<Title level={4}>
										{generateInitial(detailUser?.userName ?? '')}
									</Title>
								</div>
							</div>
							<div className="flex flex-col">
								<Title level={5}>{detailUser?.userName ?? '-'}</Title>
								<Paragraph className="text-secondary mt-1">
									User ID: {detailUser?.refUserId ?? '-'}
								</Paragraph>
							</div>
						</div>
						<div className="flex flex-col">
							<Paragraph size="l" className="text-secondary">
								Email & Nomor HP
							</Paragraph>
							<div className="flex items-center mt-1">
								<Title
									level={5}
									className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[300px]"
									title={detailUser?.userEmail}
								>
									{detailUser?.userEmail ?? '-'}
								</Title>
								<Title level={5} className="ml-1 whitespace-nowrap">
									/ {detailUser?.userPhone ?? '-'}
								</Title>
							</div>
						</div>
						{showEditKyc ? (
							<Button
								variant="secondary"
								size="sm"
								onClick={updateEkyModal.openModal}
							>
								<span className="mr-2 text-blue-500 -mt-0.5">
									<Icon name="pen" color="currentColor" />
								</span>
								Edit Profil
							</Button>
						) : (
							<span></span>
						)}
					</div>
				)}
			</div>
			<div className="w-full p-6 flex flex-col bg-neutral-0 rounded-lg shadow-base">
				<Paragraph weight="bold" size="l" className="mb-0.5">
					Daftar dokumen
				</Paragraph>
				<Paragraph size="r">
					Data yang ditampilkan sesuai dengan filter tanggal dokumen yang
					diunggah:
					<span className="font-bold ml-1">
						{dayjs(start).format('DD MMM YYYY')} -{' '}
						{dayjs(end).format('DD MMM YYYY')}
					</span>
				</Paragraph>
				<Tabs
					className="mt-2"
					size="md"
					tabBarGutter={24}
					defaultActiveKey={currentTab}
					onChange={onChangeTab}
					items={[
						{
							children: (
								<div className="mt-2">
									<Filter
										searchPlaceholder="Cari weblink id"
										searchValue={searchWeblink}
										status={weblinkSigningStatus}
										onApplyFilter={onApplyWeblinkFilter}
										onChangeStatus={onChangeWeblinkStatus}
										onSearch={onSearchWeblink}
									/>
									{isShowWeblinkList ? (
										<div className="mt-4">
											<WeblinkTable
												data={weblinkList}
												refUserId={detailUser?.refUserId ?? ''}
												isLoading={isGetWeblinkSignerLoading}
												limit={weblinkPagination.pageSize}
												page={weblinkPagination.currentPage}
												totalCount={weblinkPagination.totalCount}
												onChangePageSize={weblinkPagination.onChangePageSize}
												onChangeCurrentPage={
													weblinkPagination.onChangeCurrentPage
												}
												onSort={onSortWeblink}
											/>
										</div>
									) : (
										<div className="mt-4">
											<EmptyDocument
												title="Belum ada data yang dapat ditampilkan"
												description="Data Anda akan muncul di sini"
												stroke
											/>
										</div>
									)}
								</div>
							),
							key: 'weblink',
							label: 'Weblink',
						},
						{
							children: (
								<div className="mt-2">
									<Filter
										searchValue={searchNonWeblink}
										status={nonWeblinkSigningStatus}
										onApplyFilter={onApplyNonWeblinkFilter}
										onChangeStatus={onChangeNonWeblinkStatus}
										onSearch={onSearchNonWeblink}
										searchPlaceholder="Cari id atau nama dokumen"
									/>
									{isShowNonWeblinkList ? (
										<div className="mt-4">
											<NonWeblinkTable
												data={nonWeblinkList}
												refUserId={detailUser?.refUserId ?? ''}
												isLoading={isGetNonWeblinkSignerLoading}
												limit={nonWeblinkPagination.pageSize}
												page={nonWeblinkPagination.currentPage}
												totalCount={nonWeblinkPagination.totalCount}
												onChangePageSize={nonWeblinkPagination.onChangePageSize}
												onChangeCurrentPage={
													nonWeblinkPagination.onChangeCurrentPage
												}
												onSort={onSortNonWeblink}
											/>
										</div>
									) : (
										<div className="mt-4">
											<EmptyDocument
												title="Belum ada data yang dapat ditampilkan"
												description="Data Anda akan muncul di sini"
												stroke
											/>
										</div>
									)}
								</div>
							),
							key: 'non-weblink',
							label: 'Non-Weblink',
						},
					]}
				/>
			</div>
			{updateEkyModal.visible && (
				<Suspense>
					<UpdateEkycModal
						isVisible={updateEkyModal.visible}
						signersDetail={signersDetail}
						id={detailUser?.userId ?? ''}
						closeModal={updateEkyModal.closeModal}
						onSubmit={onUpdateEkyc}
					/>
				</Suspense>
			)}
		</>
	);
};

export default DetailSigner;
