import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import SignerActivityLogFilter from 'features/dashboard/know-your-customer/signer-activity-log/filter';
import SignerActivityLogTable from 'features/dashboard/know-your-customer/signer-activity-log/table';
import { useProfileStore } from 'common/stores/profile';
import SectionHeader from 'common/components/section-header';
import { downloadFile } from 'common/utils/file';
import ToastMessage from 'common/utils/toast-message';
import { camelize } from 'common/utils/transformer';
import usePagination from 'common/hooks/use-pagination';
import useSort from 'common/hooks/use-sort';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import {
	downloadSignerActivityLog,
	getSignerActivityLog,
} from 'services/usage';
import type { FilterActivityLog, Log } from './signer-activity-log.interfaces';
import type { ActivityLogSort } from './table/table.interfaces';

const SignerActivityLog = () => {
	const checkPermission = useProfileStore((state) => state.checkPermission);
	const navigate = useNavigate();
	const { sort, setSort } = useSort({
		by: 'created_at',
		order: 'desc',
	});
	const {
		onChangeCurrentPage,
		onChangePageSize,
		pageSize,
		currentPage,
		totalCount,
	} = usePagination();

	const [filter, setFilter] = useState<FilterActivityLog>({
		search: '',
		dates: [dayjs(), dayjs()],
	});
	const [logs, setLogs] = useState<Log[]>([]);
	const [isTyping, setIsTyping] = useState(false);

	const { isFetching } = useQuery({
		queryKey: [
			'getSignerActivityLog',
			filter.search,
			filter.dates,
			currentPage,
			pageSize,
			sort,
		],
		queryFn: () =>
			getSignerActivityLog({
				updated_ref_user_id: filter.search,
				start_date: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
				end_date: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
				page: currentPage,
				limit: pageSize,
				sort_by: `${sort.by}:${sort.order}`,
			}),
		onSuccess: (response) => {
			const data = response?.data?.items ?? [];
			const sanitizedResponse = camelize(data) as Log[];

			const items = sanitizedResponse.map((item) => {
				return {
					...item,
					key: item.id,
				};
			});

			setLogs(items);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
		onSettled: () => {
			setIsTyping(false);
		},
	});

	const { refetch: download } = useQuery({
		queryKey: ['downloadSignerActivityLog'],
		enabled: false,
		queryFn: () =>
			downloadSignerActivityLog({
				updated_ref_user_id: filter.search,
				start_date: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
				end_date: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
				page: currentPage,
				limit: pageSize,
				sort_by: `${sort.by}:${sort.order}`,
			}),
		onSuccess: (response) => {
			const filename = `log-edit-profil-${dayjs(filter.dates[0]).format(
				'DD/MM/YYYY'
			)}-${dayjs(filter.dates[1]).format('DD/MM/YYYY')}.xlsx`;

			downloadFile(response, filename);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const downloadLogs = () => {
		ToastMessage({
			type: 'info',
			label: 'Dokumen sedang diunduh.',
		});
		download();
	};

	const onSetFilter = (filter: FilterActivityLog) => {
		setFilter({
			...filter,
		});
	};

	const onSearchValue = (value: string) => {
		setFilter({
			...filter,
			search: value,
		});
	};

	const onSort = (sort: ActivityLogSort) => {
		setSort(sort);
	};

	const onSearchInput = () => {
		setIsTyping(true);
	};

	useEffect(() => {
		if (!checkPermission('ekyc-dashboard', 'update-signer')) {
			return navigate('/unauthorized');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="py-8">
			<SectionHeader
				containerClass="mb-6"
				title="Riwayat edit data penandatangan"
				backHandler={() => navigate(-1)}
			/>
			<div className="w-full p-6 flex flex-col bg-neutral-0 rounded-lg shadow-base">
				<SignerActivityLogFilter
					searchValue={filter.search}
					filter={filter}
					onSearch={onSearchValue}
					onSearchInput={onSearchInput}
					onSetFilter={onSetFilter}
					onDownload={downloadLogs}
				/>
				<SignerActivityLogTable
					data={logs}
					isLoading={isFetching || isTyping}
					limit={pageSize}
					page={currentPage}
					onChangeCurrentPage={onChangeCurrentPage}
					totalCount={totalCount}
					onChangePageSize={onChangePageSize}
					onSort={onSort}
				/>
			</div>
		</div>
	);
};

export default SignerActivityLog;
