import { useNavigate } from 'react-router-dom';
import { Button, Typography, Icon } from '@squantumengine/horizon';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useProfileStore } from 'common/stores/profile';
import DownloadIcon from 'common/components/icons/download';
import SpSelectRangePicker from 'common/components/sp-range-picker';
import type { DateOptions } from 'common/components/sp-range-picker/sp-range-picker.interfaces';
import type { FilterProps } from './filter.interfaces';

const { Paragraph } = Typography;

const Filter = ({
	filter,
	dateType,
	isDownloadDisabled,
	isDownloadLoading,
	onSetDateType,
	onSetFilter,
	onDownload,
}: FilterProps) => {
	const navigate = useNavigate();
	const checkPermission = useProfileStore((state) => state.checkPermission);

	const download = () => {
		onDownload();
	};

	const onSetDates = (dateRange: [Dayjs, Dayjs]) => {
		onSetFilter({
			...filter,
			dates: dateRange,
		});
	};

	const onSetDataType = (dateType: DateOptions) => {
		onSetDateType(dateType);
	};

	const latestUpdatedAt = `Terakhir diperbarui: ${dayjs().format(
		'DD MMM YYYY, HH:mm'
	)}`;

	return (
		<div className="flex justify-between items-center">
			<div className="flex items-center">
				<SpSelectRangePicker
					maxRangeInDays={31}
					showOptions
					dates={filter.dates}
					dateType={dateType}
					setDates={onSetDates}
					setDateType={onSetDataType}
				/>
				<Paragraph className="text-disable ml-4" size="r" weight="regular">
					{latestUpdatedAt}
				</Paragraph>
			</div>
			<div className="flex items-center gap-3">
				{checkPermission('ekyc-dashboard', 'set-auto-reminder') && (
					<Button
						variant="secondary"
						onClick={() =>
							navigate('/dashboard/know-your-customer/send-reminder')
						}
					>
						<span className="flex items-center justify-between text-nowrap gap-2">
							<Icon name="bell-on" size="md" color="currentColor" />
							Pengaturan pengingat otomatis
						</span>
					</Button>
				)}
				<Button
					variant="secondary"
					loading={isDownloadLoading}
					disabled={isDownloadDisabled}
					onClick={download}
				>
					<span className="mr-2">
						<DownloadIcon />
					</span>
					Unduh data
				</Button>
			</div>
		</div>
	);
};

export default Filter;
